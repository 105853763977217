<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('https://i.pinimg.com/originals/1c/d8/47/1cd84761e613e417f08ea18f3586a884.jpg');
        "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a
                class="title"
                title="Merchandising Material
"
                >Video
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="page-content">
            <p>
              <iframe
                frameborder="0"
                src="//www.youtube.com/embed/qjpFTvaDIGk"
                width="640"
                height="360"
                class="note-video-clip"
              ></iframe>
            </p>
            <p>
              <iframe
                frameborder="0"
                src="//www.youtube.com/embed/PK_kULhiYUc"
                width="640"
                height="360"
                class="note-video-clip"
              ></iframe>
            </p>
            <p>
              <iframe
                frameborder="0"
                src="//www.youtube.com/embed/5FseqMn78o0"
                width="640"
                height="360"
                class="note-video-clip"
              ></iframe>
            </p>
            <p>
              <iframe
                frameborder="0"
                src="//www.youtube.com/embed/GsB1f5uoGWc"
                width="640"
                height="360"
                class="note-video-clip"
              ></iframe>
            </p>
            <p>
              <iframe
                frameborder="0"
                src="//www.youtube.com/embed/SebRctjvrVU"
                width="640"
                height="360"
                class="note-video-clip"
              ></iframe
              ><br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Video"
};
</script>
